import React, { Fragment } from "react";
import { Grid, InputLabel } from '@material-ui/core';

function Featured(props) {
    return (
        props.posts.length > 0 && props.posts !== undefined &&
        <div className="blog-index-featured">
        <Fragment>
        {props.name !== undefined && props.name !== "" && props.name !== "null"  &&
        <h4 className="blog-index-featured-title" color="secondary">{props.name}</h4>
        }
        {props.posts.slice(0,3).map((post) => {
            return(
            <div className="featured" dangerouslySetInnerHTML={{ __html: post }} />
        )})}
        </Fragment>
        </div>
    );
}

export default Featured;
