import { TableSortLabel } from '@material-ui/core';
import axios from 'axios';

export const getPosts = async (wordPressUrl) => {
    return axios.get(wordPressUrl)
        .then(res => {
            return { data: res.data, totalPosts: res.headers['x-wp-total'], totalPages: res.headers['x-wp-totalpages'] }
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getPostsByCategory = async (wordPressUrl) => {
    return getPosts(wordPressUrl);
}

export const getPostsByTag = async (wordPressUrl) => {
    return getPosts(wordPressUrl);
}

export const getCategories = async (wpUrl) => {
    const wordPressCategoriesUrl = wpUrl + 'categories';
    return axios.get(wordPressCategoriesUrl)
        .then(res => res.data)
        .then(categories => {
            var filteredCats = categories.filter((category) => {
                if (category.name == 'Uncategorized') {
                    return false;
                }
                return true;
            });
            var results = Array.from(filteredCats.map((category) => {
                return ({ id: category.id, name: category.name });
            }));
            return results;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getTags = async (wpUrl) => {
    const wordPressTagsUrl = wpUrl + 'tags?per_page=100';
    return axios.get(wordPressTagsUrl)
        .then(res => res.data)
        .then(tags => {
            var results = Array.from(tags.map((tag) => {
                return ({ id: tag.id, name: tag.name });
            }))
            return results;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getFeaturedTagId = async (wpUrl) => {
    const wordPressTagsUrl = wpUrl + 'tags?search=featured';
    return axios.get(wordPressTagsUrl)
        .then(res => res.data)
        .then(tags => {
            var result = tags.map((tag) => {
                if(tag.name == "featured")
                    return tag.id
            })
            return result;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}
