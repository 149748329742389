import { useEffect, useState } from "react";
function SearchBlog({ searchInput }) {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const EnterHandler = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        searchInput(searchValue);
      }
    };
    document.addEventListener("keydown", EnterHandler);
    //clean up function
    return () => document.removeEventListener("keydown", EnterHandler);
  }, [searchInput, searchValue]);

  return (
    <div
      className="search-container"
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        borderRadius: "6px",
        border: "1.5px solid #acadac",
      }}
    >
      <input
        type="text"
        onChange={(e) => setSearchValue(e.target.value)}
        style={{
          fontSize: "22px",
          fontWeight: "300",
          fontFamily:"Arial, 'FontAwesome'",
          padding: "31px",
          paddingBottom: " 32px",
          borderRadius: "6px 0 0 6px",
        }}
        className="form-control search-input"
        placeholder="&#xF002; Search by title or keyword"
      />
      <button
        onClick={() => searchInput(searchValue)}
        style={{
          textAlign: "center",
          letterSpacing: "0.2rem",
          width: "250px",
          fontSize: " 30px",
          padding: "8px",
          background: "#fc6b03",
          border: "2px solid #fc6b03",
          borderRadius: "0 5px 5px 0",
        }}
      >
        Search
      </button>
    </div>
  );
}

export default SearchBlog;
